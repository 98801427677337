import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { AccountCircle } from '@material-ui/icons'
import Menu from '@material-ui/core/Menu/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useAppState } from '../../AppStateContext'
import AppActionType from '../../../../AppActionType'
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

interface TabOption {
    label: string
    pathName: string
}

interface Props {
    currentTabValue: string
    tabs: Array<TabOption>
    onItemSelect: (item: any) => void
}

const useStyles = makeStyles(() => ({
    root: {
    },
    spacing: {
    	flexGrow: 1,
    },
    tab: {
        maxWidth: 320,
    },
    wrapper: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block',
    },
}))

const CostToolLink = (props: any) => (
    <a
        {...props}
        href="https://cost-analyzer.baseops.platform.devops-at-scale.com/overview.html"
        rel="noopener noreferrer"
        target="_blank"
    />
)

const NavigationElements = ({ currentTabValue, tabs, onItemSelect }: Props) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorCodeEl, setanchorCodeEl] = React.useState(null)
    const [anchorBuildEl, setanchorBuildEl] = React.useState(null)
    const [anchorTestEl, setanchorTestEl] = React.useState(null)
    const [anchorDeployEl, setanchorDeployEl] = React.useState(null)
    const [anchorReleaseEl, setanchorReleaseEl] = React.useState(null)
    const [anchorRunEl, setanchorRunEl] = React.useState(null)
    
    const [appState, dispatch] = useAppState()

    const onTabSelect = (_: any, value: string) => onItemSelect(value)
    const onMenuOpenRequest = (event: any) => setAnchorEl(event.currentTarget)
    const onMenuClose = () => setAnchorEl(null)
    const onLogOutRequest = () => {
        dispatch({ type: AppActionType.CLEAR_SIGN_IN })
    }
    
    const handleCodeClick = (event: any) => setanchorCodeEl(event.currentTarget)
    const handleBuildClick = (event: any) => setanchorBuildEl(event.currentTarget)
    const handleTestClick = (event: any) => setanchorTestEl(event.currentTarget)
    const handleDeployClick = (event: any) => setanchorDeployEl(event.currentTarget)
    const handleReleaseClick = (event: any) => setanchorReleaseEl(event.currentTarget)
    const handleRunClick = (event: any) => setanchorRunEl(event.currentTarget)
    
    const handleCodeClose = () => setanchorCodeEl(null)
    const handleBuildClose = () => setanchorBuildEl(null)
    const handleTestClose = () => setanchorTestEl(null)
    const handleDeployClose = () => setanchorDeployEl(null)
    const handleReleaseClose = () => setanchorReleaseEl(null)
    const handleRunClose = () => setanchorRunEl(null)

    const openJenkins = () => {
      window.open("https://jenkins.baseops.platform.devops-at-scale.com", '_blank');
      handleBuildClose();
    }
    const openChe = () => {
      window.open("https://che.platform.devops-at-scale.com/dashboard/", '_blank');
      handleCodeClose();
    }
    const openGitLab = () => {
        window.open("https://gitlab.platform.devops-at-scale.com/dashboard/", '_blank');
        handleCodeClose();
      }    
    const openGerrit = () => {
      window.open("https://gerrit.platform.devops-at-scale.com", '_blank');
      handleCodeClose();
    }    
	const openEfs = () => {
      window.open("https://baseops.platform.devops-at-scale.com/efs", '_blank');
      handleRunClose();
    } 
	const openConsole = () => {
      window.open("https://console.baseops.platform.devops-at-scale.com", '_blank');
      handleRunClose();
    }
    const openArchiva = () => {
      window.open("https://baseops.platform.devops-at-scale.com/archiva", '_blank');
      handleRunClose();
    }
    const openSonarqube = () => {
      window.open("https://baseops.platform.devops-at-scale.com/sonarqube", '_blank');
      handleRunClose();
    }
    const openWso2is = () => {
      window.open("https://wso2is.baseops.platform.devops-at-scale.com", '_blank');
      handleRunClose();
    }
    const openWarRoom = () => {
      window.open("https://chat.platform.devops-at-scale.com/channel/release_war_room", '_blank');
      handleRunClose();
    }
    const openHarbor = () => {
      window.open("https://harbor.platform.devops-at-scale.com", '_blank');
      handleRunClose();
    }
    const openArgoCD = () => {
        window.open("https://argocd.platform.devops-at-scale.com", '_blank');
        handleRunClose();
      }    
    const openCostTool = () => {
      window.open("https://cost-analyzer.baseops.platform.devops-at-scale.com", '_blank');
      handleRunClose();
    }
    const openClusterDashboard = () => {
      window.open("https://cost-analyzer.baseops.platform.devops-at-scale.com/grafana/d/at-cost-analysis-pod/pod-cost-and-utilization-metrics?orgId=1", '_blank');
      handleRunClose();
    }
    const openLogging = () => {
      window.open("https://baseops.platform.devops-at-scale.com/logging/", '_blank');
      handleRunClose();
    }
     const openKubernetes = () => {
      window.open("https://kubernetes.platform.devops-at-scale.com", '_blank');
      handleRunClose();
    }
    const openAccount = () => {
      window.open("https://wso2is.baseops.platform.devops-at-scale.com/dashboard", '_blank');
      onMenuClose();
    }
    const openLogout = () => {
      dispatch({ type: AppActionType.CLEAR_SIGN_IN })
      window.open("https://wso2is.baseops.platform.devops-at-scale.com/samlsso?logout=true", '_self');
    }
    
        
    return (
        <>
            <Tabs value={currentTabValue} onChange={onTabSelect} classes={{ root: classes.root }}>
                {tabs.map(option => {
                    const isCurrentWorkspaceTab = option.pathName === '/current-workspace'
                    const hasCurrentWorkspace = !!appState.currentWorkspace

                    if (isCurrentWorkspaceTab && !hasCurrentWorkspace) {
                        return <></>
                    }

                    return (
                        <Tab
                            key={option.label}
                            label={
                                option.label +
                                (isCurrentWorkspaceTab ? ' ' + appState.currentWorkspace.name : '')
                            }
                            component={Link}
                            to={option.pathName}
                            value={option.pathName}
                            classes={{ root: classes.tab, wrapper: classes.wrapper }}
                        />
                    )
                })}

            </Tabs>
            
            <Box m={4} />
            <Typography variant="button" display="block" color="inherit">Global Tools</Typography>
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="code-menu" aria-haspopup="true" onClick={handleCodeClick}>
              Code
            </Button>
            <Menu id="code-menu" anchorEl={anchorCodeEl} keepMounted open={Boolean(anchorCodeEl)} onClose={handleCodeClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openChe}>
                <img height="35" width="35" alt="CheIDE" src="https://ide.platform.devops-at-scale.com/dashboard/assets/branding/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >Che Cloud IDE</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openGerrit}>
                <img height="35" width="35" alt="Gerrit" src="https://gerrit-review.googlesource.com/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >Gerrit Code Review</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openGitLab}>
                <img height="35" width="35" alt="GitLab" src="https://gitlab.platform.devops-at-scale.com/assets/favicon-7901bd695fb93edb07975966062049829afb56cf11511236e61bcf425070e36e.png" />
                <Box m={1} /><Typography variant="button" display="block" >GitLab Repos</Typography>
              </MenuItem>              
            </Menu>
            
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="build-menu" aria-haspopup="true" onClick={handleBuildClick}>
              Build
            </Button>
            <Menu id="build-menu" anchorEl={anchorBuildEl} keepMounted open={Boolean(anchorBuildEl)} onClose={handleBuildClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openJenkins}>
                <img height="35" width="35" alt="Jenkins" src="http://jenkins.io/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >BaseOps Jenkins</Typography>
              </MenuItem>
            </Menu>
            
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="test-menu" aria-haspopup="true" onClick={handleTestClick}>
              Test
            </Button>
            <Menu id="test-menu" anchorEl={anchorTestEl} keepMounted open={Boolean(anchorTestEl)} onClose={handleTestClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openSonarqube}>
                <img height="35" width="35" alt="Sonarqube" src="https://baseops.platform.devops-at-scale.com/sonarqube/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >SonarQube</Typography>
              </MenuItem>
            </Menu>
            
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="deploy-menu" aria-haspopup="true" onClick={handleDeployClick}>
              Deploy
            </Button>
            <Menu id="deploy-menu" anchorEl={anchorDeployEl} keepMounted open={Boolean(anchorDeployEl)} onClose={handleDeployClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openArchiva}>
                <img height="35" width="35" alt="Archiva" src="https://baseops.platform.devops-at-scale.com/archiva/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >Archiva Repository</Typography>
              </MenuItem>
            </Menu>
            
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="release-menu" aria-haspopup="true" onClick={handleReleaseClick}>
              Release
            </Button>
            <Menu id="release-menu" anchorEl={anchorReleaseEl} keepMounted open={Boolean(anchorReleaseEl)} onClose={handleReleaseClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openHarbor}>
                <img height="35" width="35" alt="Harbor" src="https://harbor.platform.devops-at-scale.com/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >Harbor</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openWarRoom}>
                <img height="35" width="35" alt="WarRoom" src="https://chat.platform.devops-at-scale.com/assets/favicon_512.png" />
                <Box m={1} /><Typography variant="button" display="block" >Virtual War Room</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openArgoCD}>
                <img height="35" width="35" alt="WarRoom" src="https://argocd.platform.devops-at-scale.com/assets/favicon/favicon-32x32.png" />
                <Box m={1} /><Typography variant="button" display="block" >Argo CD</Typography>
              </MenuItem>              
            </Menu>
            
            <Box m={1} />
            <Button color="inherit" variant="outlined" aria-controls="run-menu" aria-haspopup="true" onClick={handleRunClick}>
              Run
            </Button>
            <Menu id="run-menu" anchorEl={anchorRunEl} keepMounted open={Boolean(anchorRunEl)} onClose={handleRunClose}
             anchorOrigin={{vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} getContentAnchorEl={null}
            >
              <MenuItem onClick={openEfs}>
                <img height="35" width="35" alt="EFS" src="https://baseops.platform.devops-at-scale.com/efs/static/img/icons/favicon-32x32.png" />
                <Box m={1} /><Typography variant="button" display="block" >EFS Volume Browser</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openConsole}>
                <img height="35" width="35" alt="Console" src="https://console.baseops.platform.devops-at-scale.com/static/images/favicon.png" />
                <Box m={1} /><Typography variant="button" display="block" >Console</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openWso2is}>
                <img height="35" width="35" alt="WSO2IS" src="./wso2is_favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >WSO2 Identity Server</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openCostTool}>
                <img height="35" width="35" alt="CostTool" src="https://cost-analyzer.baseops.platform.devops-at-scale.com/favicon.ico" />
                <Box m={1} /><Typography variant="button" display="block" >Cost Dashboard</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openClusterDashboard}>
                <img height="35" width="35" alt="ClusterDashboard" src="https://baseops.platform.devops-at-scale.com/monitoring/public/img/fav32.png" />
                <Box m={1} /><Typography variant="button" display="block" >Cluster Dashboard</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openLogging}>
                <img height="35" width="35" alt="Logging" src="https://www.elastic.co/favicon-32x32.png" />
                <Box m={1} /><Typography variant="button" display="block" >Logging & Monitoring</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={openKubernetes}>
                <img height="35" width="35" alt="Kubernetes" src="./kubernetes-logo.png" />
                <Box m={1} /><Typography variant="button" display="block" >Kubernetes Cluster</Typography>
              </MenuItem>
            </Menu>
                        
            <div className={classes.spacing} ></div>

            <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={onMenuOpenRequest}
            >
                <AccountCircle />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                  keepMounted open={Boolean(anchorEl)} onClose={onMenuClose} getContentAnchorEl={null}
            >
                <MenuItem onClick={openAccount}>
                	<ListItemIcon><AccountBoxIcon fontSize="large" /></ListItemIcon>
          			<ListItemText primary="My Account" />
                </MenuItem>
                <MenuItem onClick={openLogout}>
                	<ListItemIcon><ExitToAppIcon fontSize="large" /></ListItemIcon>
          			<ListItemText primary="Logout" />
                </MenuItem>
            </Menu>
        </>
    )
}

export default NavigationElements
