import * as React from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import { useState } from 'react'
import { ChangeEvent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TokenStorage } from '../../../signin/data/TokenStorage'
import { decodeToken } from "react-jwt"

interface Props {
    isOpen: boolean
    isBusy: boolean
    onCloseRequest: () => void
    onConfirm: (name: string, artifactCodeRepo: string) => void
}

interface State {
    name: string
    newRepository: string
    gitRepository: string
    currentCategory: Category | null
    currentVariant: ArtifactVariant | null
}

const DEFAULT_STATE = {
    name: '',
    newRepository: 'true',
    gitRepository: '',
    currentCategory: null,
    currentVariant: null,
}

const useStyles = makeStyles({
    progress: {
        marginRight: 10,
    },
    formControl: {
        marginBottom: 16,
    },
})

interface Category {
    id: string
    name: string
    variants: Array<ArtifactVariant>
}

interface ArtifactVariant {
    id: string
    name: string
}

const ARTIFACT_OPTIONS: Array<Category> = [
    {
        id: 'python',
        name: 'Python',
        variants: [
            {
                id: 'flask',
                name: 'Flask, Python 3.7',
            },
            {
                id: 'django',
                name: 'Django, Python 3.7',
            },
            {
                id: 'tensorflow',
                name: 'Tensorflow 2.0 Beta, Python 3.5',
            },
        ],
    },
    {
        id: 'node',
        name: 'Node.js',
        variants: [
            { id: 'node_basic', name: 'Node.js, v10' },
            { id: 'express', name: 'Express, v10' },
            { id: 'joa', name: 'Koa, v10' },
        ],
    },
    {
        id: 'java',
        name: 'Java',
        variants: [
            { id: 'ee', name: 'Java EE, Java 8' },
            { id: 'spring_boot', name: 'Spring Boot, Java 11' },
        ],
    },
]

function AddArtifactDialog({ isOpen, isBusy, onConfirm, onCloseRequest }: Props) {

    
    const tokenStorage = TokenStorage.getInstance()
    const decodedToken = decodeToken(tokenStorage.getToken() as string) as any
    const userName = decodedToken['user_name'] as string

    const [state, setState] = useState<State>({ ...DEFAULT_STATE })
    const classes = useStyles()

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, name: userName + ':' + event.currentTarget.value })

    const onNewChange = (event: ChangeEvent<any>) => 
        setState({ ...state, newRepository: event.currentTarget.value })

    const onGitChange = (event: ChangeEvent<HTMLInputElement>) =>
        setState({ ...state, gitRepository: event.currentTarget.value })

    const onCategorySelect = (event: ChangeEvent<any>) => {
        const currentCategory = ARTIFACT_OPTIONS.find(opt => opt.id === event.target.value)!
        setState({ ...state, currentCategory, currentVariant: null })
    }

    const onVariantSelect = (event: ChangeEvent<any>) => {
        const currentVariant = state.currentCategory!.variants.find(
            opt => opt.id === event.target.value
        )!
        setState({ ...state, currentVariant })
    }

    const variantOptions = state.currentCategory ? state.currentCategory.variants : []
    const shouldDisableButton =
        state.name.length === 0 || 
            (state.newRepository === 'true' && (!state.currentCategory || !state.currentVariant)) ||
            (state.newRepository === 'false' && state.gitRepository.length === 0) ||
            isBusy

    return (
        <Dialog open={isOpen} onClose={onCloseRequest} fullWidth={true}>
            <DialogTitle>Add new artifact</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} className={classes.formControl}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Artifact name"
                        type="text"
                        fullWidth
                        autoComplete="off"
                        onChange={onNameChange}
                        disabled={isBusy}
                    />
                </FormControl>

                <FormControl fullWidth={true} className={classes.formControl}>
                    <RadioGroup aria-label="newrepository" name="newrepository" value={state.newRepository} onChange={onNewChange} row>
                        <FormControlLabel
                            value={'true'}
                            control={<Radio color="primary" />}
                            label="Create new repository"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value={'false'}
                            control={<Radio color="primary" />}
                            label="Use existing repository"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                </FormControl>

                { state.newRepository === 'true' && <FormControl fullWidth={true} className={classes.formControl}>
                    <InputLabel>Artifact type</InputLabel>
                    <Select
                        value={state.currentCategory ? state.currentCategory.id : null}
                        onChange={onCategorySelect}
                        fullWidth
                    >
                        {ARTIFACT_OPTIONS.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                }

                { state.newRepository === 'true' && <FormControl fullWidth={true} className={classes.formControl}>
                    <InputLabel>Artifact variant</InputLabel>
                    <Select
                        value={state.currentVariant ? state.currentVariant.id : null}
                        onChange={onVariantSelect}
                        fullWidth
                        disabled={!state.currentCategory}
                    >
                        {variantOptions.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                }

                { state.newRepository === 'false' && <FormControl fullWidth={true} className={classes.formControl}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="gitRepository"
                        label="Git repository url (https://...)"
                        type="text"
                        fullWidth
                        autoComplete="off"
                        onChange={onGitChange}
                        disabled={isBusy}
                    />
                </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseRequest}>Cancel</Button>
                <Button
                    onClick={() => onConfirm(state.name, state.gitRepository)}
                    color="primary"
                    disabled={shouldDisableButton}
                >
                    <CircularProgress
                        className={classes.progress}
                        style={{ opacity: isBusy ? 1 : 0 }}
                        size={16}
                    />
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddArtifactDialog
